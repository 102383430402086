import React from "react"
import { Layout, Section, TextContainer, Hero, Sidebar } from "../components"

import * as styles from "../styles/services.module.scss"

export default function Services() {
  return (
    <Layout theme="dark">
      <Hero
        className={styles.servicesHero}
        theme="dark"
        image={{ src: "about-bg.png", alt: "about-bg" }}
        hero={{
          title: "The experience to cover your needs",
          text:
            "From tax preparation to auditing services, S B Tan can cover your needs. We have had experience auditing various companies in services, trading, manufacturing, shipping and freight forwarding, MCSTs etc.             ",
        }}
      ></Hero>
      <Sidebar>
        <Section name="Accounting" className={styles.servicesSection}>
          <TextContainer
            title="Accounting"
            text="Peachtree Solutions has been providing accounting & GST services to companies in services and trading on monthly, quarterly or yearly basis. For accounting fee quote, please provide latest management accounts, estimated monthly sales, receipts, purchases and expenses in volume and value and e-mail to sawbin@sbtan.com"
          ></TextContainer>
        </Section>
        <Section
          name="Annual Unaudited Report"
          className={styles.servicesSection}
        >
          <TextContainer
            title="Annual Unaudited Report"
            text="Annual unaudited report is according to the format required by local tax authorities (IRAS) for corporate tax returns as stated in IRAS’ circular. For fee quote, please provide latest management accounts and e-mail to sawbin@sbtan.com. Our fees start from S$600, excluding disbursements."
          ></TextContainer>
        </Section>

        <Section name="Auditing" className={styles.servicesSection}>
          <TextContainer
            title="Auditing"
            text="S B Tan Audit PAC (previously known as S B Tan & Co), Public Accountants and Chartered Accountants – We are in Sport Singapore’s panel of audit firms and have been auditors of several major National Sports Associations. We are also auditors of companies in services, trading, manufacturing, shipping and freight forwarding and MCSTs. For audit fee quote, please provide latest audited and management accounts, estimated monthly revenue, receipts, purchases and expenses in volume and value and e-mail to sawbin@sbtan.com"
          ></TextContainer>
        </Section>

        <Section
          name="Entrepass Application"
          className={styles.servicesSection}
        >
          <TextContainer
            title="Entrepass Application"
            text="We have successfully applied for EntrePass on behalf of our foreigner clients who have come to Singapore to be entrepreneurs, and enjoy significant tax savings. Our fee for EntrePass application is S$2,500, including 10-page business plans, application and correspondences with the authorities. For further enquiries, e-mail to admin@sbtan.com"
          ></TextContainer>
        </Section>

        <Section
          name="Insurance & Investment"
          className={styles.servicesSection}
        >
          <TextContainer
            title="Insurance & Investment"
            text="Our associate is with Phillip Securities Pte Ltd. For general and life insurance quotes, and investments (including shares, land banking/investment, unit trusts and money market), e-mail to sngfy@phillip.com.sg, or call Fook Yuan at 9147 7438 for appointment at your convenience."
          ></TextContainer>
        </Section>

        <Section
          name="Sage 50 & Peachtree Support"
          className={styles.servicesSection}
        >
          <TextContainer
            title="Sage 50 & Peachtree Support"
            text="We arrange for our qualified accountants to provide Sage 50 (ex Peachtree) set up, training and support from $490."
          ></TextContainer>
        </Section>

        <Section name="Secretarial Services" className={styles.servicesSection}>
          <TextContainer
            title="Secretarial Services"
            text="Our corporate secretarial services include (but are not limited to) the following:

            Filling of annual returns to ACRA
            Striking off company
            Tax returns: Form C/CS
            Tax agent
            Company incorporation
            Change in capital structure (Transfer of shares)
            Our fee for striking off application and other services starts from $500. For fee quote, please provide latest management accounts and e-mail to sawbin@sbtan.com"
          ></TextContainer>
        </Section>

        <Section name="SME Tips" className={styles.servicesSection}>
          <TextContainer
            title="SME Tips"
            text="Enjoy full tax exemption up to S$100,000 of taxable income for first 3 years, and 50% tax exemption for the next S$200,000 of taxable income. Criteria: New private limited company with fewer than 20 individual shareholders and at least one individual shareholder having at least 10% shareholding.

            We advise our clients how to minimise tax legally (ie tax planning) when we finalise the annual accounts of the Company."
          ></TextContainer>
        </Section>
        <Section name="Tax Returns" className={styles.servicesSection}>
          <TextContainer
            title="Tax Returns"
            text="Our fees for corporate tax returns start from S$500 for small active companies, which includes submission of ECI (Estimated Chargeable Income) to IRAS, tax computation, schedules and completion of Form C.

            We recommend that ECI be submitted online to IRAS immediately after the Company’s financial year-end for up to 10 months of interest-free instalment by IRAS, and latest within 3 months after the Company’s financial year-end.
            
            Upon appointment of our firm as tax agents, please authorise us for the relevant IRAS e-services in CorpPass."
          ></TextContainer>
        </Section>
      </Sidebar>
    </Layout>
  )
}
